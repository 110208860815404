import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import axios from 'axios'
import Select from 'react-select'

import { FilterTypesList, SeparationTypeList, SubDomainList } from '../constants/filters-lists'

import './search-form.css'

function SearchForm({ onSearchClick }) {
    const initialQuery = { area: undefined, city: undefined, name: '', domain: undefined, showRemote: false, filterType: undefined, separationType: undefined, positionType: undefined }
    const [queryParams, setQueryParams] = useState(initialQuery)
    const [showModal, setShowModal] = useState(false)
    const [areasList, setAreasList] = useState([])
    const [citiesList, setCitiesList] = useState([])
    const [domainsList, setDomainsList] = useState([])

    const getList = (url, setList) => {
        axios.get(url, {
            headers: {
                'Access-Control-Allow-Credentials': true
            }
        }).then((res) => {
            setList([{ id: -1, name: 'הכל' }, ...res.data]);
        });
    }

    useEffect(() => {
        getList('https://api.shmura.org.il/lists/areas/', setAreasList)
        getList('https://api.shmura.org.il/lists/cities/', setCitiesList)
        getList('https://api.shmura.org.il/lists/domains/', setDomainsList)
    }, [])

    const selectProps = {
        classNamePrefix: 'search-form-select',
        className: 'search-form-field-select',
        placeholder: '',
        getOptionLabel: o => o.name,
        getOptionValue: o => o.id
    }

    return (
        <div>
            <span>סינון מעסיקים</span>
            <div className='search-form-form-container'>
                <div className='search-form-field'>
                    <b>תחום</b>
                    <Select {...selectProps}
                        onChange={d => setQueryParams({ ...queryParams, domain: d.id })}
                        options={domainsList}
                        value={domainsList.find(d => d.id === queryParams.domain) || ''} />
                </div>
                <div className='search-form-field'>
                    <b>תת תחום</b>
                    <Select  {...selectProps}
                        isDisabled={queryParams.domain == null}
                        onChange={s => setQueryParams({ ...queryParams, subDomain: s.id })}
                        options={SubDomainList}
                        value={SubDomainList.find(s => s.id === queryParams.subDomain) || ''} />
                </div>
                <div className='search-form-field'>
                    <b>אזור</b>
                    <Select {...selectProps}
                        onChange={a => setQueryParams({ ...queryParams, area: a.id })}
                        options={areasList}
                        value={areasList.find(a => a.id === queryParams.area) || ''} />
                </div>
                <div className='search-form-field'>
                    <b>עיר</b>
                    <Select  {...selectProps}
                        isDisabled={queryParams.area == null}
                        onChange={c => setQueryParams({ ...queryParams, city: c.id })}
                        options={citiesList.filter(c => c.area_id == queryParams.area)}
                        value={citiesList.find(c => c.id === queryParams.city) || ''} />
                </div>
                <div className='search-form-field search-form-field-checkbox'>
                    <input type="checkbox"
                        id='remote-checkbox'
                        onChange={e => setQueryParams({ ...queryParams, showRemote: e.target.value })} />
                    <label htmlFor='remote-checkbox'>הצג אפשרויות עבודה מרחוק מכל הארץ </label>
                </div>
                <div className='search-form-buttons-container'>
                    <button className='search-form-button' onClick={() => onSearchClick(queryParams)}>חפש</button>
                    <button className='search-form-button' onClick={() => {
                        Promise.resolve()
                        .then(() => setQueryParams(initialQuery))
                        .then(() => onSearchClick())
                    }}>נקה</button>
                </div>
                <button className='search-form-advanced-search' onClick={() => setShowModal(true)}>חיפוש מתקדם</button>
                <Modal isOpen={showModal} style={{
                    content: {
                        top: '30px',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translateX(-50%)',
                        width: '60vw',
                        height: '350px',
                        borderRadius: 'calc(.3rem - 1px)'
                    },
                }}>
                    <h3 className='advanced-search-h3'>חיפוש מתקדם</h3>
                    <div className='advanced-search-flex-box'>
                        <div className='search-form-field'>
                            <b>אפשרות סינון</b>
                            <Select {...selectProps}
                                onChange={ft => setQueryParams({ ...queryParams, filterType: ft.id })}
                                options={FilterTypesList}
                                value={FilterTypesList.find(f => f.id === queryParams.filterType) || ''} />
                        </div>
                        <div className='search-form-field'>
                            <b>הפרדה</b>
                            <Select {...selectProps}
                                onChange={st => setQueryParams({ ...queryParams, separationType: st.id })}
                                options={SeparationTypeList}
                                value={SeparationTypeList.find(s => s.id === queryParams.separationType) || ''} />
                        </div>
                    </div>
                    <div className='advanced-search-buttons-container'>
                        <button onClick={() => setShowModal(false)}>סגור</button>
                        <button onClick={() => {
                            onSearchClick(queryParams)
                            setShowModal(false)
                        }
                        }>חיפוש</button>
                    </div>
                </Modal>
            </div>
        </div>
    )
}

SearchForm.propTypes = {
    onSearchClick: PropTypes.func.isRequired
}

export default SearchForm
