import React from 'react'

import loaderGif from '../assets/loader.gif'

import './loader.css'

export default function Loader() {
    return (
        <div className='loader-blurred-layer'>
            <img src={loaderGif} className='loader-img' />
        </div>
    )
}
