import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'

import details from './company-details'
import opinions from './company-opinions'
import ratings from './company-ratings'
import tips from './company-tips'

import './company-page.css'

function CompanyPage() {
    const GENERAL = 0, OPINION = 1, RATING = 2, TIP = 3
    const tabsList = [{
        id: GENERAL,
        label: 'כללי',
        component: details
    }, {
        id: OPINION,
        label: 'חוות דעת',
        component: opinions
    }, {
        id: RATING,
        label: 'דירוג',
        component: ratings
    }, {
        id: TIP,
        label: 'טיפים לגיוס',
        component: tips
    }]
    let { id } = useParams();
    const [company, setCompany] = useState(null)
    const [activeTab, setActiveTab] = useState(0)
    const CurrentComponent = tabsList.find(t => t.id === activeTab).component

    useEffect(() => {
        axios.get(`https://api.shmura.org.il/companies/${id}`, {
            headers: {
                'Access-Control-Allow-Credentials': true
            }
        }).then(company => {
            setCompany(company.data)
        })
    }, [])

    if (!company) {
        return null
    }

    return (
        <main role="main" className='company-page-main'>
            <section className='company-page-header-section'>
                <div className='company-page-header-flex'>
                    <img src='https://shmura.rdn.org.il/assets/imgs/logoComp.PNG' width={100} />
                    <div>
                        <h1 className='company-page-header-h1'>{company.name}</h1>
                        <h3 className='company-page-header-h3'>{company.category}</h3>
                    </div>
                </div>
                <ul className='company-page-header-tabs'>
                    {tabsList.map(t => (
                        <li className={activeTab === t.id ? 'active' : null} onClick={() => setActiveTab(t.id)} key={`tab-${t.id}`}>{t.label}</li>
                    ))}
                </ul>
            </section>
            <div>
                {<CurrentComponent company={company} />}
            </div>
        </main>
    )
}

export default CompanyPage
