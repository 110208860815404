import React from 'react'
import PropTypes from 'prop-types'

import './company-tips.css'

function CompanyTips({ company }) {
  return (
    <div className='company-tips'>
      <h4 className='company-tips-h4'>טיפים</h4>
      <hr />
      <ul className='company-tips-ul'>
        {company.tips ? company.tips.map(t => (
          <li key={t.id}>{t.label}</li>
        )) : <h5 className='company-tips-h5'>אין נתונים להצגה</h5>}
      </ul>
    </div>
  )
}

CompanyTips.propTypes = {
    company: PropTypes.object.isRequired
}

export default CompanyTips
