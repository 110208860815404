import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { groupBy } from 'lodash'
import { Link } from 'react-router-dom'

import SearchForm from './search-form'
import Loader from '../common/loader'

import { FilterTypesList, PositionTypeList, SeparationTypeList } from '../constants/filters-lists'

import { hasValue } from '../utils/validations'

import bgImg from '../assets/companies-background.jpg'

import './companies-page.css'

function CompaniesPage() {

    const [companiesList, setCompaniesList] = useState([])
    const [nextPage, setNextPage] = useState(false)
    const [query, setQuery] = useState({ offset: 0, limit: 100 })
    const [isLoading, setIsLoading] = useState(false)
    const [company, setCompany] = useState('')

    const fetchData = () => {
        setIsLoading(true)
        const cleanQuery = Object.fromEntries(Object.entries(query).filter(([k, v]) => v !== undefined && v !== ''))
        const searchString = new URLSearchParams(cleanQuery).toString();
        axios.get(`https://api.shmura.org.il/companies?${searchString}`, {
            headers: {
                'Access-Control-Allow-Credentials': true
            }
        }).then((companies) => {
            setCompaniesList(companies.data);
            setNextPage(companies.data.length === 100)
            setIsLoading(false)
        });
    }

    useEffect(() => {
        fetchData()
    }, [query])

    const sortByGroup = (d1, d2) => companiesList[d2].length - companiesList[d1].length

    return (
        <main role="main" className='companies-page-main'>
            <section className='companies-page-suggest-value'>
                <h4>הצעת ערך לקהילה</h4>
                <p>ממשק מיוחד ומאתגר לחיפוש ופילטור בין משרות במשק, לסיוע והקלה במציאת משרה המתאימה לך, הן מבחינה מקצועית, הן מבחינת דרישות, אפשרויות קידום ובעיקר - מבחינה רוחנית וערכית.</p>
                <div>
                    <input type="text" placeholder='שם החברה'
                        onChange={e => {
                            setCompany(e.target.value)
                            setQuery({ ...query, name: e.target.value })
                        }}
                        value={company} />
                    <button onClick={() => setQuery({ ...query, name: company })}>חיפוש</button>
                </div>
            </section>
            <div className='companies-page-content'>
                <SearchForm onSearchClick={(newQuery = {}) => setQuery({
                    offset: 0,
                    limit: 100,
                    ...newQuery
                })} />
                {isLoading ? <Loader /> : (
                    <ul className='companies-list'>
                        {companiesList.map(company => (
                            <li className='company-card' key={company.id}>
                                <div className='company-card-header'>
                                    <div className='company-card-header-flex'>
                                        <div>
                                            <img src="https://shmura.rdn.org.il/assets/imgs/logoComp.PNG" width={40} />
                                        </div>
                                        <Link className='company-link' to={`/companies/${company.id}`}>
                                            <h6 className='company-title'>
                                                {company.name}
                                                <span className='company-region'> , {company.city_name}</span>
                                            </h6>

                                        </Link>
                                    </div>
                                    <span className='company-card-domain'>{company.domain_name}</span>
                                </div>
                                <div className='company-card-details'>
                                    <div className='company-card-address-details'>
                                        <span className='company-card-city-region'>{company.area_name}, {company.city_name}</span>
                                        <span className='company-card-address'>{company.address}</span>
                                    </div>
                                    <ul className='company-card-other-info'>
                                        {hasValue(company.filter_type) && FilterTypesList.find(f => f.id == company.filter_type) && <li className='company-card-filtering-level'>{FilterTypesList.find(f => f.id == company.filter_type).name}</li>}
                                        {hasValue(company.separation_type) && SeparationTypeList.find(s => s.id == company.separation_type) && <li className='company-card-job-declaration'>{SeparationTypeList.find(s => s.id == company.separation_type).name}</li>}
                                        {hasValue(company.position_type) && PositionTypeList.find(p => p.id == company.position_type) && <li className='company-card-splitting-level'>{PositionTypeList.find(p => p.id == company.position_type).name}</li>}
                                    </ul>
                                    <Link to='/survey'>להוספת דיווח</Link>
                                </div>
                                <div className='company-card-footer'>
                                    <span>דירוג מקצועי 3 <br />מתוך 2 מדרגות</span>
                                </div>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </main>
    )
}

export default CompaniesPage
