import React from 'react'
import PropTypes from 'prop-types'

import './company-details.css'

function CompanyDetails({ company }) {
    return (
        <div className='company-details'>
            <h3 className='company-details-h3'>{company.category} - {company.city}</h3>
            <h4 className='company-details-h4'>{company.address}</h4>
            <h5 className='company-details-h5'>תיאור החברה</h5>
            <p className='company-details-p'>{company.description || 'תיאור החברה'}</p>
            <h5 className='company-details-h5-pointer'>{'קרא עוד >>>'}</h5>
            <ul className='company-details-ul'>
                <li className='company-details-li'>
                    <b>מספר העובדים בחברה :</b>&nbsp;{company.numberOfEmployees || 'לא ידוע'}
                </li>
                <li className='company-details-li'>
                    <b>מספר החרדיות בחברה :</b>&nbsp;{company.orthodoxSumName || 'לא ידוע'}
                </li>
                <li className='company-details-li'>
                    <b>אפשרות משרה חלקית :</b>&nbsp;{company.partTimeJob ? 'אפשרי' : 'לא אפשרי'}
                </li>
                <li className='company-details-li'>
                    <b>אפשרות עבודה מרחוק :</b>&nbsp;{company.remoteWork ? company.remoteWorkOption : 'עבודה במשרד'}
                </li>
                <li className='company-details-li'>
                    <b>סינון אינטרנט :</b>&nbsp;{company.webFilteringOption || 'אין מידע'}
                </li>
                <li className='company-details-li'>
                    <b>הפרדה בין גברים לנשים :</b>&nbsp;{company.separation || 'לא ידוע'}
                </li>
            </ul>
        </div>
    )
}

CompanyDetails.propTypes = {
    company: PropTypes.object.isRequired
}

export default CompanyDetails
