import { Link, Routes, Route } from 'react-router-dom';

import CompaniesPage from './companies/companies-page'
import CompanyPage from './companies/company-page';

import './app.css';

function App() {
  return (
    <div className='app'>
      <header className='app-header'>
        <div className='app-header-img-container'>
          <img className='app-header-img' src="https://shmura.rdn.org.il/assets/imgs/logo.jpg" width={70} height={47} />
        </div>
        <h1 className='app-header-h1'>ממשק זירת משרות</h1>
        <nav className='app-nav'>
          <Link to='/companies'>חברות</Link>
          <Link to='/about'>אודותינו</Link>
          <Link to='/contact-us'>צור קשר</Link>
        </nav>
      </header>
      <Routes>
        <Route path="companies" element={<CompaniesPage />} />
        <Route path="companies/:id" element={<CompanyPage />} />
      </Routes>
    </div>
  );
}

export default App;
