import React from 'react'
import PropTypes from 'prop-types'
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts'

import './company-ratings.css'

function CompanyRatings({ company }) {
  return (
    <div className='company-ratings'>
      <h4 className='company-ratings-h4'>דירוג מקצועי</h4>
      <h5 className='company-ratings-h5'>שביעות רצון כללית {company.averageSpiritualRating ? company.averageSpiritualRating.toFixed(2) : 'לא ידוע'}</h5>
      <ul className='company-ratings-ul'>
        {company.ratings ? (
          <BarChart width={730} height={250} data={company.ratings}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          {/* <Legend />
          <Bar dataKey="pv" fill="#8884d8" />
          <Bar dataKey="uv" fill="#82ca9d" /> */}
        </BarChart>
        ) : <h5 className='company-ratings-h5'>אין נתונים להצגה</h5>}
      </ul>

    </div>
  )
}

CompanyRatings.propTypes = {
    company: PropTypes.object.isRequired
}

export default CompanyRatings
