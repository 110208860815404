import React from 'react'
import PropTypes from 'prop-types'

import './company-opinions.css'

function CompanyOpinions({ company }) {
  return (
    <div className='company-opinions'>
      <h4 className='company-opinions-h4'>חוות דעת</h4>
      <hr />
      <ul className='company-opinions-ul'>
        {company.opinions ? company.opinions.map(o => (
          <li key={o.id}>{o.label}</li>
        )) : <h5 className='company-opinions-h5'>אין נתונים להצגה</h5>}
      </ul>
    </div>
  )
}

CompanyOpinions.propTypes = {
  company: PropTypes.object.isRequired
}

export default CompanyOpinions
